<template>
  <el-container class="shopList">
    <div v-if="!showCamScreen">
      <el-header class="box add">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>企业摄像头配置列表</el-breadcrumb-item>
          <el-breadcrumb-item>摄像头列表</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>
      <div style="padding: 0 20px">
        <el-form :inline="true" :model="form3" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="摄像头名称/id"
                v-model="form3.cam_id"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="详情"
                v-model="form3.detail"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="search"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="medium"
                @click="Add"
                v-if="user_tag === 1"
                >新增摄像头</el-button
              >
              <el-button
                v-if="!showCamScreen"
                type="primary"
                size="medium"
                @click="handleCheckCam"
                >实时监测</el-button
              >
              <el-button v-else size="medium" @click="showCamScreen = false"
                >返回</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
        <div>
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="cam_id" label="摄像头id" width="180">
            </el-table-column>
            <el-table-column prop="site_id" label="站点id" width="180">
            </el-table-column>
            <el-table-column prop="cam_name" label="摄像头名称" width="180">
            </el-table-column>
            <el-table-column prop="detail" label="详情" width="180">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="lookVideo(scope.row)"
                  >查看直播</el-button
                >
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleReplyPics(scope.row)"
                  >留影回看</el-button
                >
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleAIPics(scope.row)"
                  >AI应用</el-button
                >

                <el-button
                  v-if="user_tag === 1"
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleManage(scope.row)"
                  >权限管理</el-button
                >
                <el-button
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleUpdate(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="i-page fr disib mt20">
            <el-pagination
              background
              :page-size="9"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="currentPage"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="width: 100%">
      <div class="haccp-bg p15 pr">
        <el-row :gutter="0" style="width: 100%">
          <el-col
            :span="8"
            v-for="(item, index) in camList"
            :key="index"
            v-show="camList.length > 0"
            class="card-item mr20"
          >
            <div class="card-item-title">
              <div class="button_hover" @click="handleAIPics(item)">AI应用</div>
              <div>
                {{ item.cam_name }}
              </div>
              <div class="button_hover" @click="handleReplyPics(item)">
                留影回看
              </div>
            </div>
            <div class="card-item-content">
              <live-video :id="item.cam_id" :key="item.cam_id"></live-video>
              <!-- <div :id="'wrapper'+item.id" style="height: 320px;"></div> -->
            </div>
          </el-col>
          <div v-show="camList.length == 0" class="col-fff f25">
            <span>暂未绑定摄像头</span>
          </div>
        </el-row>
        <div style="position: absolute; right: 10px; top: 20px">
          <div class="button_hover" @click="showCamScreen = false">返回</div>
        </div>
        <div style="text-align: center; z-index: 100">
          <el-pagination
            background
            hide-on-single-page
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="9"
            layout="total, prev, pager, next"
            :total="camTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      :title="handleName + title"
      :visible.sync="dialogAddgsVisible"
      @close="closeDialogAddgsVisible"
      width="30%"
    >
      <el-form
        :rules="rules"
        ref="form3"
        :model="form3"
        label-position="right"
        label-width="200px"
      >
        <el-form-item label="摄像头id:" prop="cam_id">
          <el-input
            placeholder="摄像头id"
            v-model="form3.cam_id"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="站点id:" prop="site_id">
          <el-input
            placeholder="站点id"
            v-model="form3.site_id"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="摄像头名称:" prop="cam_name">
          <el-input
            placeholder="摄像头名称"
            v-model="form3.cam_name"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="是否对外开放:">
          <div class="tl">
            <el-switch
              v-model="form3.show2"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item size="medium" label="是否对监管开放:">
          <div class="tl">
            <el-switch
              v-model="form3.show1"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item label="摄像头详情:">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="摄像头详情"
            v-model="form3.detail"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" size="medium" @click="submit">{{
          handleName
        }}</el-button>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="videoTitle"
      :visible.sync="videoShow"
      @close="closeDialogAddgsVisible"
    >
      <iframe
        :src="videoUrl"
        frameborder="0"
        width="100%"
        height="980px"
        scrolling="auto"
        ref="video-player"
      ></iframe>
    </el-dialog>

    <el-dialog
      :title="titleTxt"
      :visible.sync="picsVisible"
      width="35%"
      @close="
        imageSrc = null;
        AIData = null;
        AIType = 'body_attr';
      "
    >
      <div>
        <div
          v-if="titleTxt === 'AI应用'"
          class="df"
          style="margin-bottom: 10px"
        >
          <el-switch
            v-model="AIType"
            active-value="face_rec"
            inactive-value="body_attr"
            active-text="人脸识别"
            inactive-text="移动侦测"
            @change="switchChange"
          >
          </el-switch>
        </div>
        <div>
          <el-date-picker
            v-model="timeValue"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 100%"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            @change="handleTimeChange"
          >
          </el-date-picker>
        </div>
        <div style="margin-top: 10px" v-loading="picLoading">
          <el-image
            :src="imageSrc"
            :preview-src-list="[imageSrc]"
            style="width: 100%"
            v-if="imageSrc"
          >
          </el-image>
          <div
            v-else
            class="df aic jcc"
            style="
              color: #bbb;
              height: 100px;
              background-color: rgba(0, 0, 0, 0.2);
              margin-bottom: 10px;
            "
          >
            {{ noImageFlag ? "该摄像头暂无开起该功能" : "没有更多" }}
          </div>
        </div>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6" style="text-align: center">
            <el-button
              size="mini"
              @click="handlePre"
              v-if="titleTxt === '留影回看'"
              >上一张</el-button
            >
            <el-button size="mini" @click="handleAI(0)" v-else
              >上一张</el-button
            >
          </el-col>

          <el-col :span="6" style="text-align: center">
            <el-button
              size="mini"
              @click="handleNext"
              v-if="titleTxt === '留影回看'"
              >下一张</el-button
            >
            <el-button size="mini" @click="handleAI(1)" v-else
              >下一张</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div v-if="titleTxt === 'AI应用' && AIData && AIData.length">
        <div v-for="(item, index) in AIData" :key="'AI' + index">
          <div v-if="AIType === 'body_attr'">
            <div class="df">识别人数:{{ item.person_num }}人</div>
            <div class="df" style="flex-wrap: wrap">
              <div
                v-for="(v, i) in item.person_info"
                :key="'person' + i"
                class="df ai-item"
              >
                <div class="ai-info">
                  <div class="info">
                    {{ v.attributes.gender.name }}
                  </div>
                  <div class="title">性别</div>
                </div>
                <div class="gap"></div>

                <div class="ai-info">
                  <div class="info">{{ v.attributes.face_mask.name }}</div>
                  <div class="title">是否佩戴口罩</div>
                </div>
                <div class="gap"></div>

                <div class="ai-info">
                  <div class="info">{{ v.attributes.headwear.name }}</div>
                  <div class="title">是否佩戴帽子</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="df" v-if="item.result">
              识别人数:{{ item.result.length }}人
            </div>
            <div class="df" v-else>未检测到人脸</div>
            <div class="df" style="flex-wrap: wrap">
              <div
                class="df ai-item"
                v-for="(v, i) in item.result"
                :key="'face' + i"
              >
                <div class="ai-info">
                  <div class="info">{{ v.subjects[0].subject }}</div>
                  <div class="title">识别结果</div>
                </div>
                <div class="gap"></div>
                <div class="ai-info">
                  <div class="info">
                    {{ (v.subjects[0].similarity * 100).toFixed(2) }}%
                  </div>
                  <div class="title">概率</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedUser"
          @change="handleCheckedCitiesChange"
          size="medium"
        >
          <el-checkbox
            style="width: 20%; margin: 20px"
            v-for="user in userList"
            :label="user.user_id"
            :key="user.user_id"
            :border="true"
            ><div>{{ user.user_name }}</div></el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" round @click="handleSaveUser">保存</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import api from "@/util/extra-api";
import dayjs from "dayjs";
import LiveVideo from "../DepGragh/components/LiveVideo";
export default {
  components: {
    LiveVideo,
  },
  data() {
    return {
      camId: null,
      timeValue: "",
      imageSrc: "",
      picLoading: false,
      picsVisible: false,
      manageVisible: false,
      tableData: [],
      videoUri: "https://extlive.esunadv.com/?url=",
      videoTitle: "",
      form3: {
        id: "",
        company_id: "",
        cam_id: "",
        site_id: "",
        cam_name: "",
        detail: "",
        pager_offset: "0",
        pager_openset: "10",
        show1: 0, //监管
        show2: 0, //对外
      },
      videoUrl: "",
      handleName: "新增",
      title: "企业摄像头",
      currentPage: 1,
      allCurrentPage: 1,
      total: 0,
      allTotal: 0,
      dialogAddgsVisible: false,
      companyId: [],
      videoShow: false,
      checkAll: false,
      checkedUser: [],
      isIndeterminate: true,
      rules: {
        cam_id: [
          { required: true, message: "请输入摄像头id", trigger: "blur" },
        ],
        site_id: [{ required: true, message: "请输入站点id", trigger: "blur" }],
        cam_name: [
          { required: true, message: "请输入摄像头名称", trigger: "blur" },
        ],
      },
      userList: [],
      selectedId: null,
      showCamScreen: false,
      camList: [],
      camListQuery: {
        company_id: "",
        pager_offset: 0,
        pager_openset: 9,
      },
      camTotal: 0,
      user_tag: 2,
      titleTxt: "留影回看",
      AIType: "body_attr",
      noImageFlag: false,
      AIData: null,
    };
  },
  mounted() {
    this.form3.company_id = localStorage.getItem("company_id"); //获取当前企业
    this.camListQuery.company_id = localStorage.getItem("company_id"); //获取当前企业
    this.getData();
    let user = JSON.parse(localStorage.getItem("user"));
    this.user_tag = user.user_tag;
  },
  methods: {
    switchChange() {
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.getAIimg(3, this.timeValue);
    },
    getAIimg(type, time) {
      this.picLoading = true;
      let data = {
        type: this.AIType,
        siteId: this.siteId,
        camId: this.camId,
        time,
      };
      if (type === 0 || type === 1) {
        data.next = type;
      }
      api.get("/v1/wx/companyDetail/camImg", data).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.noImageFlag = false;
            this.imageSrc = res.data.url;
            this.picLoading = false;
            this.picsVisible = true;
            this.AIData = res.data.ai.map((v) => {
              console.log(v[this.AIType].info, 123);
              return JSON.parse(v[this.AIType].info);
            });

            console.log(this.AIData, 123);
            this.timeValue = dayjs
              .unix(res.data.timestamp)
              .format("YYYY-MM-DD HH:mm:ss");
          } else {
            this.picLoading = false;
            this.AIData = null;
            if (this.imageSrc) {
              this.imageSrc = "";
              this.noImageFlag = false;
            } else {
              this.noImageFlag = true;
            }
          }
        }
      });
    },
    handleAI(type) {
      let time = "";
      if (!type) {
        time = dayjs(this.timeValue)
          .subtract(1, "minute")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        time = dayjs(this.timeValue)
          .add(1, "minute")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      this.getAIimg(type, time);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.camListQuery.pager_offset = val - 1;
      this.initCam();
    },
    initCam() {
      this.camListQuery.company_id = this.form3.company_id;
      axios.get("/pc/company-cam/list", this.camListQuery).then((res) => {
        if (res.code == 200) {
          this.camList = res.data.cam_list;
          this.camTotal = res.data.pager_count;
        }
      });
    },
    handleCheckCam() {
      this.showCamScreen = true;
      if (this.showCamScreen) this.initCam();
    },
    handleSaveUser() {
      let data = {
        user_id: this.checkedUser,
        company_id: this.form3.company_id,
        other_table_name: "cam",
        other_table_id: this.selectedId,
      };
      axios.post("/pc/company-user-iot/save", data).then((res) => {
        if (res.code == 200) {
          this.manageVisible = false;
        }
      });
    },
    handleCheckAllChange(val) {
      this.checkedUser = val ? this.userList.map((m) => m.user_id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.checkedUser = value;
      //this.checkedUser = value.map(m => m.user_id)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.userList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length;
    },
    handleManage(row) {
      this.selectedId = row.id;
      axios
        .get("/pc/company-user-iot/getHasIotUser", {
          company_id: this.form3.company_id,
          other_table_name: "cam",
          other_table_id: this.selectedId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.userList = res.data;
            this.checkedUser = res.data
              .filter((m) => m.tag)
              .map((m) => m.user_id);
            this.manageVisible = true;
          }
        });
    },
    handleAIPics(row) {
      this.titleTxt = "AI应用";
      this.picsVisible = true;
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.camId = row.cam_id;
      this.siteId = row.site_id;
      this.getAIimg(3, this.timeValue);
    },
    handleReplyPics(row) {
      this.titleTxt = "留影回看";
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm");
      this.camId = row.id;
      this.picLoading = true;
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
          this.picsVisible = true;
        });
    },
    handleTimeChange(time) {
      this.picLoading = true;
      if (this.titleTxt === "留影回看") {
        axios
          .get("/pc/company-cam/camImg", {
            camId: this.camId,
            time: this.timeValue,
          })
          .then((response) => {
            this.imageSrc = response.data;
            this.picLoading = false;
          });
      } else {
        this.timeValue = dayjs(time).format("YYYY-MM-DD HH:mm:ss");
        this.getAIimg(3, this.timeValue);
      }
    },
    handlePre() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .subtract(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    handleNext() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .add(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    getData() {
      this.form3.id = "";
      axios.get("/pc/company-cam/list", this.camListQuery).then((res) => {
        res = res.data;
        if (res) {
          this.tableData = res.cam_list;
          this.total = res.pager_count;
        }
      });
    },
    search() {
      this.getData();
    },
    handleUpdate(item) {
      this.form3.id = item.id;
      this.form3.detail = item.detail;
      this.form3.cam_id = item.cam_id;
      this.form3.show1 = item.show1 == 1;
      this.form3.show2 = item.show2 == 1;
      this.form3.cam_id = item.cam_id;
      this.form3.site_id = item.site_id;
      this.form3.cam_name = item.cam_name;

      this.dialogAddgsVisible = true;
      this.handleName = "修改";
    },
    Add() {
      this.dialogAddgsVisible = true;
      this.form3.cam_id = "";
      this.handleName = "添加";
    },
    lookVideo(row) {
      this.videoTitle = row.detail;
      this.videoUrl = this.videoUri + row.cam_id;
      this.videoShow = true;
      //console.log("url",this.videoUri);
    },
    handleDelete(row) {
      axios.get("/pc/company-cam/delete/" + row.id).then((res) => {
        this.dialogAddgsVisible = false;
        this.getData(this.form3);
      });
    },
    submit() {
      this.$refs["form3"].validate((valid) => {
        if (valid) {
          this.form3.show1 = this.form3.show1 ? 1 : 0;
          this.form3.show2 = this.form3.show2 ? 1 : 0;
          if (!this.form3.id) {
            axios.post("/pc/company-cam/save", this.form3).then((res) => {
              this.dialogAddgsVisible = false;
              this.form3.cam_id = "";
              this.form3.detail = "";
              this.getData(this.form3);
            });
          } else {
            axios.post("/pc/company-cam/update", this.form3).then((res) => {
              this.dialogAddgsVisible = false;
              this.form3.cam_id = "";
              this.form3.detail = "";
              this.getData(this.form3);
            });
          }
        } else {
          return false;
        }
      });
    },
    closeDialogAddgsVisible() {
      this.dialogAddgsVisible = false;
    },
    currentChange(page) {
      this.currentPage = page;
      this.form3.pager_offset = String(page - 1);
      this.camListQuery.pager_offset = page - 1;
      this.getData();
    },
    allCurrentChange(page) {
      this.allCurrentPage = page;
      this.form3.pager_offset = String(page - 1);
      this.getAllData();
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  /*height: 20vw*/
}

.ai-item {
  border: 1px solid #eee;
  margin-top: 10px;
  margin-right: 10px;
  .ai-info {
    margin: 10px;
    padding: 0 5px;
    .info {
      line-height: 26px;
      font-size: 18px;
      background: none;
    }
    .title {
      line-height: 14px;
      color: #bbb;
      font-size: 12px;
    }
  }
  .gap {
    margin: 15px 0;
    width: 1px;
    background-color: #ccc;
  }
}
/deep/.el-table__empty-block{
  width: 100% !important;
}
</style>
